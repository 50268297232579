import React, { useState, useEffect, useRef } from "react";
import "../css/carrusel.css";

const CarouselComponent = ({ images = [], titleImage }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [direction, setDirection] = useState(1); // 1: derecha, -1: izquierda
  const trackRef = useRef(null);

  const totalImages = images.length > 0 ? [...images, ...images] : [];

  const baseSpeed = 50; // Velocidad base en px/seg
  const animationSpeed = baseSpeed * speedMultiplier * direction;

  useEffect(() => {
    if (trackRef.current) {
      trackRef.current.style.animationDuration = `${Math.abs(animationSpeed)}s`;
      trackRef.current.style.animationDirection = direction === 1 ? "normal" : "reverse";
    }
  }, [animationSpeed, direction]);

  // Funciones para desplazar manualmente el carrusel
  const moveCarousel = (dir) => {
    setDirection(dir);
    setSpeedMultiplier(3); // Acelera la velocidad por 3x
    setTimeout(() => setSpeedMultiplier(1), 2000); // Vuelve a la normalidad en 2s
  };

  return (
    <div className="carousel-container">
      {images.length === 0 ? (
        <div className="carousel-empty">No hay imágenes disponibles</div>
      ) : (
        <>
          <div className="top-content">
            {titleImage && <img src={titleImage} alt="Title" className="title-image" />}
          </div>
          <div
            className="carousel-wrapper"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
            <div ref={trackRef} className={`carousel-track ${isPaused ? "paused" : ""}`}>
              {totalImages.map((img, index) => (
                <div key={index} className="carousel-item">
                  <div className="image-container">
                    <img src={img} alt={`Imagen ${index}`} className="thumbnail" />
                    <img className="agua" src={require("../img/agua.png")} alt="Marca de agua" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button className="prev-button" onClick={() => moveCarousel(-1)}>&larr;</button>
          <button className="next-button" onClick={() => moveCarousel(1)}>&rarr;</button>
        </>
      )}
    </div>
  );
};

export default CarouselComponent;
