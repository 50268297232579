import React from 'react';

const ContactSection = () => {
  const styles = {
    container: {
      marginTop: '5%',
      width: '60%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '2% 20%', 
      boxSizing: 'border-box',
    },
    title: {
      fontSize: '56px',
      fontFamily: '"lulo-clean-w01-one-bold", sans-serif',
      fontWeight: 'bold',
      marginBottom: '9%',
      marginTop: '-4%',
      letterSpacing: '-0.10em',
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      textAlign: 'left',
      marginLeft: '4%',
    },
    infoBox: {
      flex: 1,
      margin: '0 10px',
    },
    heading: {
      fontSize: '25px',
      fontWeight: 'bold',
      letterSpacing: '0.15em',
      marginBottom: '15%',
    },
    textSmall: {
      fontSize: '15px',
      color: '#686262',
      marginBottom: '3%',
      letterSpacing: '0.15em',
      fontFamily: 'barlow-extralight, barlow, sans-serif',
    },
    socialContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
      gap: '15px',
    },
    socialIcon: {
      width: '25px', 
      height: '24px',
      cursor: 'pointer',
    },
    mapContainer: {
      backgroundColor: 'black',
      width: '1150px',
      height: '411px', 
      marginTop: '70px',
      marginBottom: '10%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iframe: {
      width: '100%',
      height: '100%',
      border: '0',
      filter: 'invert(0.8) hue-rotate(180deg)',
    },
  };

  // Función para abrir WhatsApp con mensaje predefinido
  const openWhatsApp = () => {
    const phoneNumber = '3346788868'; // Tu número de WhatsApp
    const message = encodeURIComponent('¡Hola! Quisiera más información.');
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  return (
    <div id="contacto" className='contenedor-contacto flex-container' style={styles.container}>
      <div className='lulo contenedorDirecciones batman' style={styles.infoContainer}>
        <div className='lulo ' style={styles.infoBox}>
          <div className='lulo subtituloContacto' style={styles.heading}>DIRECCIÓN</div>
          <div className='barlow' style={styles.textSmall}>AV. AMERICAS 326</div>
          <div className='barlow' style={styles.textSmall}>44200 Guadalajara, Jal. Mexico.</div>
        </div>
        <div style={styles.infoBox}>
          <div className='lulo subtituloContacto' style={styles.heading}>CONTACTO</div>
          <div className='barlow contacto-texto' style={styles.textSmall}>33 4678 8868</div>
          <div className='barlow' style={styles.textSmall}>contacto@brandeostore.com</div>
        </div>
        <div className='lulo' style={styles.infoBox}>
          <div className='lulo subtituloContacto' style={styles.heading}>HORARIOS</div>
          <div className='barlow' style={styles.textSmall}>lun - vie 9:00 am – 6:00 pm</div>
          <div className='barlow' style={styles.textSmall}>Sábados 9:00 am – 2:00 pm</div>
        </div>
      </div>

      {/* Redes sociales */}
      <div style={styles.socialContainer}>
        <img 
          src={require('../img/w.PNG')} 
          alt="WhatsApp" 
          style={styles.socialIcon} 
          onClick={openWhatsApp} 
        />
        <img src={require('../img/g.PNG')} alt="Twitter" style={styles.socialIcon} />
        <img src={require('../img/l.PNG')} alt="Instagram" style={styles.socialIcon} />
        <img src={require('../img/i.PNG')} alt="LinkedIn" style={styles.socialIcon} />
      </div>

      {/* Mapa */}
      <div className="mapa" style={styles.mapContainer}>
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.6247993617365!2d-103.37793604911798!3d20.67670950310759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b1d5eaa24789%3A0x7545d30549c105ff!2sAv.%20de%20las%20Am%C3%A9ricas%20326%2C%2044200%20Guadalajara%2C%20Jal.%2C%20M%C3%A9xico!5e0!3m2!1ses!2sus!4v1639377638083!5m2!1ses!2sus"
          style={styles.iframe}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactSection;
