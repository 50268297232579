import React from 'react';

const VideoComponent = () => {

  const styles = {
    outerContainer: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      overflow: 'hidden',
    },
    videoContainer: {
      position: 'relative',
      width: '80%', // Se ajusta automáticamente
      maxWidth: '800px', // Máximo tamaño del video
      aspectRatio: '16 / 9', // Mantiene proporción 16:9
    },
    video: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    overlayText: {
      position: 'absolute',
      bottom: '10%', // Ajusta la posición del texto
      right: '5%',
      fontSize: '1.1rem', // Tamaño relativo
      fontWeight: 'bold',
      fontFamily: '"Lulo Clean W01 One Bold", sans-serif',
      color: 'black',
      letterSpacing: '0.04em',
      textDecoration: 'none',
      zIndex: 2,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      padding: '8px 12px',
      borderRadius: '5px',
    },
  };

  return (
    <div className="videoCentrar" style={styles.outerContainer}>
      <div style={styles.videoContainer}>
        <video style={styles.video} autoPlay loop muted>
          <source src={require('../video/centro.mp4')} type="video/mp4" />
          Tu navegador no soporta el elemento de video.
        </video>
      </div>
    </div>
  );
};

export default VideoComponent;
