import React from "react";

const CenteredMenu = () => {

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end", 
      width: "100%",
      padding: "10px 20px",
      boxSizing: "border-box",
      backgroundColor: "white",
      zIndex: 10,
      position: "sticky",
      top: "0",
    },
    rightContainer: {
      display: "flex",
      alignItems: "center",
      width: "440px",
      marginRight: "3%", 
    },
    imageRight: {
      height: "75px",
      width: "auto",
      cursor: "pointer",
      marginLeft: "3%", 
      transition: "all 0.3s ease",
    },
    colorBlack: {
      color: "black",
      fontFamily: '"Lulo Clean W01 One Bold", sans-serif',
      transition: "all 0.3s ease",
      letterSpacing: "0.04em",
      fontSize: "13px",
      paddingTop: "8%",
    },
    mediaQuery: {
      container: {
        flexDirection: "row", 
        padding: "5px 10px",
        alignItems: "center",
      },
      rightContainer: {
        width: "100%", 
        marginRight: "0", 
      },
      imageRight: {
        height: "50px", 
        marginLeft: "2%",
      },
      colorBlack: {
        fontSize: "12px", 
        paddingTop: "4%", 
      },
    },
  };

  const isMobile = window.innerWidth <= 768;
  const appliedStyles = {
    container: isMobile ? { ...styles.container, ...styles.mediaQuery.container } : styles.container,
    rightContainer: isMobile
      ? { ...styles.rightContainer, ...styles.mediaQuery.rightContainer }
      : styles.rightContainer,
    imageRight: isMobile
      ? { ...styles.imageRight, ...styles.mediaQuery.imageRight }
      : styles.imageRight,
    colorBlack: isMobile
      ? { ...styles.colorBlack, ...styles.mediaQuery.colorBlack }
      : styles.colorBlack,
  };

  return (
    <div style={appliedStyles.container}>
      <div className="conten-brandeo" style={appliedStyles.rightContainer}>
        <img
        title="Ir a inicio"
          className="superior"
          src={require(`../img/brandeo.PNG`)}
          alt="Inicio"
          style={appliedStyles.imageRight}
          onClick={() => window.location.href = "/"}
        />
      </div>
    </div>
  );
};

export default CenteredMenu;