import React from "react";
import { useParams } from "react-router-dom"; // Importa useParams
import CarouselComponent from "./carouselComponent";

const CarouselList = ({ carousels }) => {
  // Obtener el id de la URL
  const { id } = useParams();

  // Filtrar el carousel por id
  const filteredCarousels = carousels.filter(carousel => carousel.id === id);

  return (
    <div className="carousel-list">
      {filteredCarousels.map((carousel, index) => (
        <CarouselComponent
          key={index}
          mainImage={carousel.mainImage}
          images={carousel.images}
          titleImage={carousel.titleImage}
          title={carousel.title}
          workList={carousel.workList}
        />
      ))}
    </div>
  );
};

export default CarouselList;
