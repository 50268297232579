import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const TwoImagesLeftAligned = () => {
  const [isOverlapping, setIsOverlapping] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const textoPromocionales = document.getElementById('textoPromocionales');
      const textoNosotros = document.getElementById('textoNosotros');

      if (!textoPromocionales || !textoNosotros) return;

      const promRect = textoPromocionales.getBoundingClientRect();
      const nosRect = textoNosotros.getBoundingClientRect();

      const isIntersecting =
        promRect.bottom > nosRect.top &&
        promRect.top < nosRect.bottom &&
        promRect.right > nosRect.left &&
        promRect.left < nosRect.right;

      setIsOverlapping(isIntersecting);
    };

    window.addEventListener('scroll', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleResize);
    };
  }, []);

  const styles = {
    container: {
      position: 'fixed',
      top: '10%',
      left: '7.5%',
      pointerEvents: 'none',
      width: '20%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: 10,
    },
    textoIzquierda: {
      color: '#686262',
      letterSpacing: '0.35em',
      cursor: 'pointer',
      lineHeight: '1.1em',
      fontSize: '17px',
      marginLeft: '5%',
      width: '450px',
      marginTop: '-7%',
      marginBottom: '20px', // Agrega un margen inferior fijo
    },
    textTop: {
      position: 'absolute',
      top: '37%',
      left: '18%',
      fontSize: '33px',
      color: isOverlapping ? 'white' : 'black',
      lineHeight: '1.2em',
      letterSpacing: '0.15em',
      transform: 'rotate(-90deg)',
      transformOrigin: 'bottom left',
    },
    imageBottom: {
      position: 'absolute',
      bottom: '15%',
      left: '4.5%',
      width: '300px',
      display: isOverlapping ? 'none' : 'block',
    },
    imageBottomInvertida: {
      position: 'absolute',
      bottom: '15%',
      left: '4.5%',
      width: '280px',
      display: isOverlapping ? 'block' : 'none',
    },
  };

  return (
    <div style={styles.container}>
      <label style={styles.textoIzquierda} className="barlow izquierda-texto-respo">
        PERSONALIZADOS A TU MEDIDA
      </label>
      <div id="textoPromocionales" className="anto" style={styles.textTop}>
        PROMOCIONALES 
      </div>
      {location.pathname === '/' && (
        <React.Fragment>
          <img
            className='imegenOcultar'
            src={require(`../img/elementos-1.png`)}
            alt="Imagen Inferior"
            style={styles.imageBottom}
          />
          <img
            className='imegenOcultar'
            src={require(`../img/elementos-2.png`)}
            alt="Imagen Inferior"
            style={styles.imageBottomInvertida}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default TwoImagesLeftAligned;