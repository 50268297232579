import React, { useState, useEffect } from 'react';
import '../css/SplitScreen.css'; // Asegúrate de incluir este archivo CSS

const SplitScreen = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Retraso para asegurarnos de que la clase de animación se aplique correctamente
    const timeout = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div class="contenedor-nosotros" className={`container ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="leftSide" id='textoNosotros'>
        <div class="div-1">
          <label class="nosotros-text">Sobre nosotros </label>
        </div>
        <div class="div-2">
        <label class="texto-nosotros">
          BRANDEO nació con la visión de transformar los productos promocionales en experiencias de marca. Desde el inicio nos enfocamos en diseñar productos premium, hechos a la medida de cada cliente.
          </label>
        <label class="texto-nosotros">
          Somos una empresa impulsada por la creatividad, la innovación y una tradición familiar en el diseño. Cada artículo que creamos refleja identidad, propósito y un enfoque detallado en la excelencia.
        </label>
        <label class="texto-nosotros">
          Hoy colaboramos con marcas líderes que buscan destacar a través de nuevas propuestas.
        </label>
        </div>
      </div>
      <div className="rightSide">
      <img class="img-certificacion" src={require(`../img/certificacion-1.jpg`)} alt="Certificación 1" />
      <img class="img-certificacion2" src={require(`../img/certificacion-2.jpg`)} alt="Certificación 2" />
      </div>
    </div>
  );
};

export default SplitScreen;
