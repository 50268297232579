import React, { useState } from 'react';

const VerticalMenu = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const styles = {
    menuContainer: {
      position: 'fixed',
      zIndex: 9,
      top: '16%', // Asegura que no se encime con superior.js
      right: '5.5%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    menuItem: {
      fontSize: '13px',
      fontWeight: 'normal',
      textDecoration: 'none',
      writingMode: 'vertical-lr',
      fontFamily: '"Lulo Clean W01 One Bold", sans-serif',
      display: 'block',
      transition: 'all 0.3s ease',
      color: 'black',
      letterSpacing: '0.04em',
      marginBottom: '20px', // Agrega un margen inferior entre los elementos
    },
    menuItemHover: {
      textDecoration: 'underline',
      fontSize: '14px',
    },
    '@media (max-width: 768px)': {
      menuContainer: {
        top: '12%',
      },
      menuItem: {
        fontSize: '12px',
        marginBottom: '25px', // Incrementa el espaciado en pantallas pequeñas
      },
    },
  };
  
  

  const menuItems = [
    { text: "NOSOTROS >", href: "/nosotros" },
    { text: "PROYECTOS >", href: "/clientes" },
    { text: "CERTIFICACIONES >", href: "contact" },
  ];
  
  return (
    <div style={styles.menuContainer} class="conten-menu">
      {menuItems.map((item, index) => (
        <a
          class="a-menu"
          key={index}
          href={item.href} // Enlace al id del div
          style={{
            ...styles.menuItem,
            ...(hoveredIndex === index ? styles.menuItemHover : {}),
          }}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          {item.text}
        </a>
      ))}
    </div>
  );
};

export default VerticalMenu;
