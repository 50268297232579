const carouselData = [
    {
      mainImage: require('../img/clientes/ferrero/1.jpg'),
      images: [
        require('../img/clientes/ferrero/1.jpg'),
        require('../img/clientes/ferrero/2.jpg'),
        require('../img/clientes/ferrero/3.jpg'),
        require('../img/clientes/ferrero/4.jpg'),
        require('../img/clientes/ferrero/5.jpg'),
        require('../img/clientes/ferrero/6.jpg'),
        require('../img/clientes/ferrero/7.jpg'),
        require('../img/clientes/ferrero/8.jpg'),
        require('../img/clientes/ferrero/9.jpg'),
        require('../img/clientes/ferrero/10.jpg'),
        require('../img/clientes/ferrero/11.jpg'),
        require('../img/clientes/ferrero/12.jpg'),
        require('../img/clientes/ferrero/13.jpg'),
        require('../img/clientes/ferrero/14.jpg'),
        require('../img/clientes/ferrero/15.jpg'),
        require('../img/clientes/ferrero/16.jpg'),
        require('../img/clientes/ferrero/17.jpg'),
      ],
      descriptions: [
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
        "Ferrero",
      ],
      titleImage: require('../img/clientes/titulos/ferrero.PNG'),
      title: "Ferrero",
      workList: ["Memoria usb", "Mochila", "Squishy", "Termo"],
      id: '1',
    },
    {
      mainImage: require('../img/clientes/discrolls/1.jpg'),
      images: [
        require('../img/clientes/discrolls/1.jpg'),
        require('../img/clientes/discrolls/2.jpg'),
        require('../img/clientes/discrolls/3.jpg'),
        require('../img/clientes/discrolls/4.jpg'),
        require('../img/clientes/discrolls/5.jpg'),
        require('../img/clientes/discrolls/6.jpg'),
        require('../img/clientes/discrolls/7.jpg'),
        require('../img/clientes/discrolls/8.jpg'),
      ],
      descriptions: [
        "Driscoll",
        "Driscoll",
        "Driscoll",
        "Driscoll",
        "Driscoll",
        "Driscoll",
        "Driscoll",
        "Driscoll",
      ],
      titleImage: require('../img/clientes/titulos/driscoll.PNG'),
      title: "Discroll",
      workList: ["Loteria", "Memoria USB", "Termo", "Gorra","Balon","Caja palomitas"],
      id: '2',
    },
    {
      mainImage: require('../img/clientes/astros/1.jpg'),
      images: [
        require('../img/clientes/astros/1.jpg'),
        require('../img/clientes/astros/2.jpg'),
        require('../img/clientes/astros/3.jpg'),
        require('../img/clientes/astros/4.jpg'),
        require('../img/clientes/astros/5.jpg'),
        require('../img/clientes/astros/6.jpg'),
      ],
      descriptions: [
        "astros",
        "astros",
        "astros",
        "astros",
        "astros",
        "astros",
      ],
      titleImage: require('../img/clientes/titulos/astros.PNG'),
      title: "Astros",
      workList: ["Taza", "Peluche", "Dedo Espuma", "Leon Peluche","Termo","Alomohada"],
      id: '3',
    },
    {
      mainImage: require('../img/clientes/electrolit/1.jpg'),
      images: [
        require('../img/clientes/electrolit/1.jpg'),
        require('../img/clientes/electrolit/2.jpg'),
        require('../img/clientes/electrolit/3.jpg'),
        require('../img/clientes/electrolit/4.jpg'),
        require('../img/clientes/electrolit/5.jpg'),
        require('../img/clientes/electrolit/6.jpg'),
        require('../img/clientes/electrolit/7.jpg'),
        require('../img/clientes/electrolit/8.jpg'),
        require('../img/clientes/electrolit/9.jpg'),
      ],
      descriptions: [
        "electrolit",
        "electrolit",
        "electrolit",
        "electrolit",
        "electrolit",
        "electrolit",
        "electrolit",
        "electrolit",
        "electrolit",
      ],
      titleImage: require('../img/clientes/titulos/electrolit.PNG'),
      title: "Electrolit",
      workList: ["Carrito mandado", "Squishy", "Libreta", "Cojin","Portafolio","Bolsa","Toalla"],
      id: '4',
    },
    {
      mainImage: require('../img/clientes/jaime/1.jpg'),
      images: [
        require('../img/clientes/jaime/1.jpg'),
        require('../img/clientes/jaime/2.jpg'),
        require('../img/clientes/jaime/3.jpg'),
        require('../img/clientes/jaime/4.jpg'),
        require('../img/clientes/jaime/5.jpg'),
        require('../img/clientes/jaime/6.jpg'),
      ],
      descriptions: [
        "Jaime Ibiza",
        "Jaime Ibiza",
        "Jaime Ibiza",
        "Jaime Ibiza",
        "Jaime Ibiza",
        "Jaime Ibiza",
      ],
      titleImage: require('../img/clientes/Captura.PNG'),
      title: "Jaime Ibiza",
      workList: ["Carrito mandado", "Squishy", "Libreta", "Cojin","Portafolio","Bolsa","Toalla"],
      id: '5',
    },
    // Agrega más objetos según necesites
  ];
  
  export default carouselData;
  