const clientData = [
  {
    id: 1,
    image: require('../img/clientes/ferrero.PNG'),
    image2: require('../img/clientes/ferrero2.PNG'),
    company: 'FERRERO ROCHER DE MEXICO',
    name: 'Juan Pérez',
    percentage: 75,
    link: '/proyectos/empresa-a',
  },
  {
    id: 2,
    image: require('../img/clientes/driscol.PNG'),
    image2: require('../img/clientes/driscol2.PNG'),
    company: 'DRISCOLLS',
    name: 'María López',
    percentage: 50,
    link: '/proyectos/empresa-b',
  },
  {
    id: 3,
    image: require('../img/clientes/astros.PNG'),
    image2: require('../img/clientes/astros2.PNG'),
    company: 'ASTROS DE JALISCO',
    name: 'Carlos Gómez',
    percentage: 90,
    link: '/proyectos/empresa-c',
  },
  {
    id: 4,
    image: require('../img/clientes/electrolit.PNG'),
    image2: require('../img/clientes/electrolit2.PNG'),
    company: 'Electrolit',
    name: 'Carlos Gómez',
    percentage: 90,
    link: '/proyectos/empresa-c',
  },
  {
    id: 5,
    image: require('../img/clientes/Captura.PNG'),
    image2: require('../img/clientes/Captura.PNG'),
    company: 'Jaime Ibiza',
    name: 'Carlos Gómez',
    percentage: 90,
    link: '/proyectos/empresa-c',
  },
];
export default clientData;