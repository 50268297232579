import React, { useState } from 'react';

const SplitScreen = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="contenedor-nosotros-separado">
        <img
        title='Ver certificación'
          className="img-certificacion-separado"
          src={require("../img/certificacion-1.jpg")}
          alt="Certificación 1"
          onClick={() => openModal(require("../img/c2.PNG"))}
        />
        <img
        title='Ver certificación'
          className="img-certificacion2-separado"
          src={require("../img/certificacion-2.jpg")}
          alt="Certificación 2"
          onClick={() => openModal(require("../img/c1.PNG"))}
        />
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={closeModal}>&times;</span>
            <img src={selectedImage} alt="Certificación ampliada" />
          </div>
        </div>
      )}

      {/* ✅ CSS dentro del mismo archivo */}
      <style>{`
        .contenedor-nosotros-separado {
          display: flex;
          justify-content: center;
          gap: 20px;
          margin-top: 20px;
        }

        .img-certificacion-separado:hover,
        .img-certificacion2-separado:hover {
          transform: scale(1.1);
        }

        /* 🎯 Estilos para la modal */
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 8px;
          position: relative;
          width: 50%;
          height: 70%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .modal-content img {
          max-width: 100%;
          max-height: 100%;
        }

        .close-button {
          position: absolute;
          top: 10px;
          right: 15px;
          font-size: 24px;
          cursor: pointer;
          color: black;
        }

        .close-button:hover {
          color: red;
        }
      `}</style>
    </>
  );
};

export default SplitScreen;
